import React from 'react';


//////////iframe再読み込み



// ///////カラーピッカー（バーのみ）

// import React, { useState } from 'react';
// import ReactColorPicker from '@super-effective/react-color-picker';

// import styles from './test.module.css';

// const App = () => {
//   const [color, setColor] = useState('#3cd6bf');

//   const onColorChange = (updatedColor) => {
//     setColor(updatedColor);
//   };

//   return (
//     <div className={styles.app}>
//       <h1>
//         Selected color:
//         {color}
//       </h1>
//       <div className={styles.color_picker}>
//         <ReactColorPicker color={color} onChange={onColorChange} />
//       </div>
//     </div>
//   );
// };

// export default App;





/////都市名の入力！！

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
    || ''
  );
 
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
 
  return [value, setValue];
};

const App = () => {
  const [value, setValue] = useStateWithLocalStorage(
    'myValueInLocalStorage'
  );
 
  const onChange = event => setValue(event.target.value);
 
  return (
    <div>
      <h1>Hello React with Local Storage!</h1>
 
      <input value={value} type="text" onChange={onChange} />
 
      <p>{value}</p>


      <p>Choose your monster's colors:</p>

<div>
    <input type="color" id="head" name="head"
           value="#e66465" />
    <label for="head">Head</label>
</div>

<div>
    <input type="color" id="body" name="body"
            value="#f6b73c" />
    <label for="body">Body</label>
</div>
    </div>
  );
};
 
export default App;